import ViewButton from "./QuickView";
import FavouriteButton from "./FavouriteButton";
import CustomButton from "./CustomButton";
import styles from "./exp.module.css";

interface ListingItems {
    title: string;
    overview: string;
    slug: string;
    feature_img: string;
    listing_id: number;
    is_custom_operator: boolean;
}

export default function ListingAction(props: ListingItems) {
    return (
        <div className={styles.cardbutton}>
            <FavouriteButton listing_id={props.listing_id} />
            <ViewButton
                title={props.title}
                overview={props.overview}
                slug={props.slug}
            />
            <CustomButton is_custom_operator={props.is_custom_operator} />
        </div>
    );
}
